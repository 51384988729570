/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation
import {
  SiTypescript,
  SiJavascript,
  SiReact,
  SiExpo,
  SiKotlin,
  SiNpm,
  SiSupabase,
  SiAwsamplify,
  SiFirebase,
  SiDocker,
  SiPython,
  SiFlutter
} from "react-icons/si";

import {TbBrandReactNative} from "react-icons/tb";
import {LiaNode} from "react-icons/lia";

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "Umer Nazir",
  title: "Hi all, I'm Umer",
  subTitle: emoji(
    "I'm a Mobile App Developer skilled in React Native, dedicated to building high-performance hybrid applications with seamless user experiences."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1ofFdKF_mqscH8WvXkSObnVvC9kK7Ldlu/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/umer-nazir02",
  linkedin: "https://www.linkedin.com/in/umer-nazir/",
  gmail: "me@umernazir.com",
  // facebook: "https://www.facebook.com/saad.pasta7",
  medium: "https://medium.com/@umernazir01",
  stackoverflow: "https://stackoverflow.com/users/12460256/umer-nazir",
  whatsapp: "https://wa.me/923316126132",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle:
    "ENTHUSIASTIC MOBILE APP DEVELOPER EAGER TO EXPLORE EVERY TECH STACK AND PUSH THE BOUNDARIES OF MOBILE TECHNOLOGY!",
  skills: [
    emoji(
      "⚡ Craft highly interactive and engaging user interfaces for your mobile applications."
    ),
    emoji(
      "⚡ Emphasize exceptional UX and UI, ensuring seamless performance across Android and iOS platforms."
    ),
    emoji(
      "⚡ Integration of third party services such as Firebase/ Supabase / AWS"
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "JavaScript",
      fontAwesomeClassname: <SiJavascript />
    },
    {
      skillName: "Typescript",
      fontAwesomeClassname: <SiTypescript />
    },
    {
      skillName: "React Native",
      fontAwesomeClassname: <TbBrandReactNative />
    },
    {
      skillName: "Expo",
      fontAwesomeClassname: <SiExpo />
    },
    {
      skillName: "React",
      fontAwesomeClassname: <SiReact />
    },
    {
      skillName: "Flutter",
      fontAwesomeClassname: <SiFlutter />
    },
    {
      skillName: "NodeJs",
      fontAwesomeClassname: <LiaNode />
    },
    {
      skillName: "Kotlin",
      fontAwesomeClassname: <SiKotlin />
    },
    {
      skillName: "npm",
      fontAwesomeClassname: <SiNpm />
    },
    {
      skillName: "Supabase",
      fontAwesomeClassname: <SiSupabase />
    },
    {
      skillName: "AWS",
      fontAwesomeClassname: <SiAwsamplify />
    },
    {
      skillName: "firebase",
      fontAwesomeClassname: <SiFirebase />
    },
    {
      skillName: "docker",
      fontAwesomeClassname: <SiDocker />
    },
    {
      skillName: "python",
      fontAwesomeClassname: <SiPython />
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "Punjab University College of Information Technology",
      logo: require("./assets/images/pucit-logo.jpeg"),
      subHeader: "Bachelor of Science in Computer Science",
      // duration: "September 2017 - April 2017",
      desc: "Ranked top 10% in the program. Took courses about Software Engineering, Web Security, Operating Systems, ..."
      // descBullets: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit"]
    }
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Hybrid Mobile Development (React Native/ Flutter)", //Insert stack or technology you have experience in
      progressPercentage: "90%" //Insert relative proficiency in percentage
    },
    {
      Stack: "Backend (Firebase/Supabase/Amplify)",
      progressPercentage: "70%"
    },
    {
      Stack: "General Programming",
      progressPercentage: "70%"
    }
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Software Engineer",
      company: "Tkxel",
      companylogo: require("./assets/images/tkxel_logo.jpeg"),
      date: "Nov 2022 - Present",
      desc: "Led and mentored teams in developing scalable, high-performance solutions, enhancing system efficiency and implementing innovative features across departments using agile methodologies.",
      descBullets: [
        "Led teams in developing scalable, high-performance solutions from concept to deployment.",
        `Mentored junior engineers, promoting continuous learning, conducting code reviews, and optimizing system efficiency using
agile methodologies.`,
        "Developed solutions focusing on accessibility, scalability, and maintainability."
      ]
    },
    {
      role: "Junior Consultant",
      company: "Systems Limited",
      companylogo: require("./assets/images/systems_limited_logo.jpeg"),
      date: "Aug 2021 - Nov 2022",
      desc: "Engineered scalable software solutions, collaborated to design project-aligned features, and enhanced system stability with automated testing for improved code quality.",
      descBullets: [
        "Engineered robust, scalable software solutions with a focus on performance.",
        "Collaborated cross-functionally to design and deliver features aligned with project goals.",
        `Debugged complex issues, improved system stability, and integrated automated testing for higher code quality in development
        workflows.`
      ]
    },
    {
      role: "Associate Software Engineer",
      company: "InvoZone",
      companylogo: require("./assets/images/invozone_logo.jpeg"),
      date: "Oct 2020 - Aug 2021",
      desc: "Developed tailored software solutions, collaborated remotely on seamless feature integration, and managed timelines with a focus on high code quality through testing and refactoring.",
      descBullets: [
        "Developed custom software solutions tailored to specific business needs.",
        "Worked together remotly to seamlessly design and integrate new features.",
        `Managed project timelines, resolved issues promptly, and ensured high code quality through testing and refactoring.`
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: false // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle:
    "SOME STARTUPS AND COMPANIES THAT I HELPED TO CREATE MOBILE PRESENCE",
  projects: [
    {
      image: require("./assets/images/cafezupas-loyalty.png"),
      projectName: "Cafezupas",
      projectDesc:
        "Cafe Zupas is a fast-casual restaurant chain known for its fresh, house-made soups, salads, and sandwiches, featuring globally inspired recipes and quality ingredients.",
      footerLink: [
        {
          name: "Cafezupas",
          url: "https://cafezupas.com/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.cafezupasres.co"
        },
        {
          name: "App Store",
          url: "https://play.google.com/store/apps/details?id=com.cafezupasres.co"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/cafezupas-catering.png"),
      projectName: "Cafezupas Catering",
      projectDesc:
        "Cafe Zupas provides catering with fresh, house-made soups, salads, and sandwiches for your events, featuring globally inspired recipes and high-quality ingredients.",
      footerLink: [
        {
          name: "Cafezupas Catering",
          url: "https://catering.cafezupas.com/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.cafezupas"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/pk/app/café-zupas-catering/id1445666529"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/Linvite.png"),
      projectName: "L'invité",
      projectDesc:
        "L’invité is a unique membership that caters to its selected members’ social & business networking, and lifestyle aspirations",
      footerLink: [
        {
          name: "L'invité",
          url: "https://www.linvite.life/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.kelectric.customerapp&hl=en"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/gb/app/linvite-life/id6459233781"
        }
      ]
    },
    {
      image: require("./assets/images/dubizzle-eg.png"),
      projectName: "Dubizzle",
      projectDesc:
        "Dubizzle Egypt is a classified ads platform for buying and selling goods, job hunting, and property rentals tailored to the Egyptian market.",
      footerLink: [
        {
          name: "Dubizzle",
          url: "https://www.dubizzle.com.eg/en/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.olxmena.horizontal&hl=en"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/eg/app/dubizzle-eg-olx/id1582817937"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/kelectric-logo.png"),
      projectName: "Karachi Electric",
      projectDesc:
        "Karachi Electric is Karachi's main electricity provider, focused on modernizing infrastructure and enhancing service delivery to meet growing demands.",
      footerLink: [
        {
          name: "Karachi Electric",
          url: "https://www.ke.com.pk/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.kelectric.customerapp&hl=en"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/pk/app/ke-live/id1458106362"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/punjab-zameen.png"),
      projectName: "Punjab Zameen",
      projectDesc:
        "Punjab Government's official app for land services: verify identity, access fard types, track transactions, and manage appointments at Arazi Record Centers. Managed by Punjab Land Records Authority, Pakistan.",
      footerLink: [
        {
          name: "Punjab Zameen",
          url: "https://www.punjab-zameen.gov.pk/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.digitalapp.android&hl=en"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/ic_zameen_logo.png"),
      projectName: "Propforce",
      projectDesc:
        "As part of our affiliate network,you will have access to the most exclusive residential & commercial properties, across various countries around the globe.",
      footerLink: [
        {
          name: "PropForce",
          url: "https://www.propforce.com/"
        },
        {
          name: "Play Store",
          url: "https://play.google.com/store/apps/details?id=com.zameen.propforce&hl=en"
        },
        {
          name: "App Store",
          url: "https://apps.apple.com/qa/app/propforce-affiliates/id1499787050"
        }
        //  you can add extra buttons here.
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆 "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Google Code-In Finalist",
      subtitle:
        "First Pakistani to be selected as Google Code-in Finalist from 4000 students from 77 different countries.",
      image: require("./assets/images/codeInLogo.webp"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {
          name: "Certification",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dYkVvNjdNWjNybWJrbndFSHpNY2NFV1p4YmU0/view?usp=sharing"
        },
        {
          name: "Award Letter",
          url: "https://drive.google.com/file/d/0B7kazrtMwm5dekxBTW5hQkg2WXUyR3QzQmR0VERiLXlGRVdF/view?usp=sharing"
        },
        {
          name: "Google Code-in Blog",
          url: "https://opensource.googleblog.com/2019/01/google-code-in-2018-winners.html"
        }
      ]
    },
    {
      title: "Google Assistant Action",
      subtitle:
        "Developed a Google Assistant Action JavaScript Guru that is available on 2 Billion devices world wide.",
      image: require("./assets/images/googleAssistantLogo.webp"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {
          name: "View Google Assistant Action",
          url: "https://assistant.google.com/services/a/uid/000000100ee688ee?hl=en"
        }
      ]
    },

    {
      title: "PWA Web App Developer",
      subtitle: "Completed Certifcation from SMIT for PWA Web App Development",
      image: require("./assets/images/pwaLogo.webp"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
        {
          name: "Final Project",
          url: "https://pakistan-olx-1.firebaseapp.com/"
        }
      ]
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Blogs",
  subtitle:
    "With Love for Developing cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://medium.com/@umernazir01/react-native-vs-flutter-navigating-the-cross-platform-development-landscape-in-2024-de683f4ab769",
      title: "React Native vs Flutter",
      description:
        "A comprehensive analysis of React Native and Flutter, detailing the evolving landscape of cross-platform mobile app development in 2024. Explore key differences, strengths, and recent shifts influencing these popular frameworks."
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

// Resume Section
const resumeSection = {
  title: "Resume",
  subtitle: "Feel free to download my resume",

  // Please Provide with Your Podcast embeded Link
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Reach out to me! ☎️"),
  subtitle: [
    "Discuss a project or just want to say hi? My Inbox is open for all."
  ],
  number: "+92-331-6126132",
  email_address: "me@umernazir.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = true; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable,
  resumeSection
};
